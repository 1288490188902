import { useContext, useEffect, useRef, useState } from "react";
import "./style.scss";
import Logo from "assets/logo.webp";
import AppStore from "assets/Navbar/Download Badge/appstore.svg";
import PlayStore from "assets/Navbar/Download Badge/playstore.svg";
import { TbMenu2 } from "react-icons/tb";
import NavbarMobile from "./NavbarMobile";
import {
  Link,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";
import Drawer from "components/Drawer/Drawer";
import AccountDrawer from "components/Drawer/AccountDrawer";
import { BsCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import AuthService from "api/services/authService";
import { StateContext } from "store/Provider";
import { UserInterface } from "types/ApiInterface";
import Badge from "components/Badge";
import { MdMailOutline } from "react-icons/md";
import { BiBell } from "react-icons/bi";
import UserService from "api/services/UserService";
import ChatRoomService from "api/services/ChatRoomService";
import { ContactProps } from "pages/Chat/components/MessContact";

export const onForm = () => {
  const form = document.querySelector(".section-9");
  form?.scrollIntoView({
    behavior: "smooth",
  });
};

function NavBar() {
  const { pathname, state } = useLocation();

  const [open, setOpen] = useState<boolean>(false);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [user, setUser] = useState<UserInterface>();
  const [accountOpen, setAccountOpen] = useState<boolean>(false);
  const [notiDrawer, setNotiDrawer] = useState<boolean>(false);
  const [messDrawer, setMessDrawer] = useState<boolean>(false);

  const match = useMatch("/");
  const location = useLocation();
  const navigate = useNavigate();

  const onFeatures = () => {
    const feature = document.querySelector(".section-2");
    feature?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const onAbout = () => {
    const about = document.querySelector(".section-5");
    about?.scrollIntoView({
      behavior: "smooth",
    });
  };
  const onContact = () => {
    const contact = document.querySelector(".section-9");
    contact?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const Logout = () => {
    AuthService.Logout();
    setIsLogin(false);
    setAccountOpen(false);
    navigate("/");
  };

  useEffect(() => {
    const getLogin = async () => {
      const data = await AuthService.IsLoggedIn();
      setIsLogin(data);
    };
    const getUser = async () => {
      const data = await UserService.GetUsers({
        permaid: state.permaid,
      });
      setUser(data[0]);
    };
    if (state) {
      getUser();
    } else {
      setUser(AuthService.GetCurrentUser());
    }
    getLogin();
  }, [pathname]);

  const onMessage = () => {
    navigate("/message");
    setMessDrawer(!messDrawer);
  };

  return (
    <>
      <div className="nav-container">
        <nav className="nav-desktop flex-horizontal">
          <div className="left-nav flex-horizontal">
            <Link to={"/"}>
              <img className="logo" src={Logo} alt="" />
            </Link>

            {match && (
              <ul className="list-items flex-horizontal">
                <li>
                  <span className="text-s text-uppercase" onClick={onFeatures}>
                    Features
                  </span>
                </li>
                <li>
                  <span className="text-s text-uppercase" onClick={onAbout}>
                    About us
                  </span>
                </li>
                <li>
                  <span className="text-s text-uppercase" onClick={onContact}>
                    Contact us
                  </span>
                </li>
              </ul>
            )}
          </div>
          <ul className="right-nav flex-horizontal">
            {/* <li>
              <img src={AppStore} alt="" onClick={onForm} />
            </li>
            <li>
              <img src={PlayStore} alt="" onClick={onForm} />
            </li> */}
            {isLogin ? (
              <>
                <li>
                  <Link to={"/message"}>
                    <Badge
                      icon={<MdMailOutline size={24} />}
                      noti={false}
                      onClick={onMessage}
                    />
                  </Link>
                </li>
                {/* <li>
                  <Badge
                    icon={<BiBell size={24} />}
                    noti={false}
                    onClick={() => setNotiDrawer(!notiDrawer)}
                  />
                </li> */}
                <li>
                  <div
                    className="profile-content flex-horizontal"
                    onClick={() => setAccountOpen(true)}
                  >
                    <div className="avatar flex-vertical">
                      <img src={user?.avatar_snapshot} alt="" />
                    </div>
                    {!accountOpen ? <BsCaretDownFill /> : <BsFillCaretUpFill />}
                  </div>
                </li>
              </>
            ) : (
              <>
                <li>
                  {location.pathname.includes("profile") ? (
                    <Link to={"/login/user"}>
                      <button className="button signin-btn flex-horizontal">
                        <div className="text text-capitalize">Login</div>
                      </button>
                    </Link>
                  ) : (
                    <Link to={"/login/professional"}>
                      <button className="button signin-btn flex-horizontal">
                        <div className="text text-capitalize">Login</div>
                      </button>
                    </Link>
                  )}
                </li>
                {/* <li>
                  <Link to={"/login/user"}>
                    <button className="button signin-btn flex-horizontal">
                      <div className="text text-capitalize">Login as User</div>
                    </button>
                  </Link>
                </li> */}
              </>
            )}
          </ul>
          <Drawer
            className={"account-drawer"}
            open={accountOpen}
            onClick={() => setAccountOpen(false)}
            children={
              <AccountDrawer
                logout={Logout}
                avatar={user?.avatar_large}
                name={user?.name}
                balance={user?.balance}
                permaid={user?.permaid}
                onClose={() => setAccountOpen(false)}
              />
            }
          />

          {/* <Drawer
            className={"noti-drawer"}
            open={notiDrawer}
            title={`Notification (12)`}
            onClick={() => setNotiDrawer(false)}
            children={
              <>
                <MessNoti />
                <MessNoti />
                <MessNoti />
                <MessNoti />
              </>
            }
          />

          <Drawer
            className={"mess-drawer"}
            open={messDrawer}
            title={`Message (12)`}
            onClick={() => setMessDrawer(false)}
            children={
              <>
                <MessNoti />
                <MessNoti />
                <MessNoti />
                <MessNoti />
              </>
            }
          /> */}

          <button className="right-nav-mobile" onClick={() => setOpen(true)}>
            <TbMenu2 size={24} color="#000" />
          </button>
        </nav>
      </div>

      <NavbarMobile
        isOpen={open}
        isLogin={isLogin}
        user={user}
        onClick={() => setOpen(false)}
        logOut={Logout}
      />
    </>
  );
}

export default NavBar;
