import ApiClient from "api/apiClient";
import ApiConfig from "api/apiConfig";

class ChatRoomService {
  static type = "chat_rooms";

  static GetChatRoomList = async (where: any) => {
    const url = ApiConfig.API_URL + ApiConfig.CHAT_ROOM_INDEX;
    try {
      const response = await ApiClient.sendGet(url, where, true);
      console.log(response);
      return response;
    } catch (error) {
      console.log(error);
    }
  };
  static CreateChatRoom = async (where: any) => {
    const url = ApiConfig.API_URL + ApiConfig.CHAT_ROOM_INDEX;
    try {
      const response = await ApiClient.sendPost(this.type, url, where, true);
      console.log(response);
      return response;
    } catch (error) {
      console.log(error);
    }
  };
}

export default ChatRoomService;
