import { useSearchParams } from "react-router-dom";
import ApiClient from "api/apiClient";
import ApiConfig from "api/apiConfig";
import UserService from "api/services/UserService";
import ChatRoomService from "./ChatRoomService";
import PushNotificationService from "./PushNotiService";
import { ContactProps } from "pages/Chat/components/MessContact";

class AuthService {
  static loginGoogle = async (user: any) => {
    const params = {
      action_task: "social_auth",
      provider: "google_mobile",
      token: user.access_token,
      ip: "",
    };
    const response = await ApiClient.sendPost(
      null,
      ApiConfig.API_URL + ApiConfig.USER_OAUTH,
      params,
      false
    );
    this.SaveAuth(response);
    return response;
  };

  static SaveAuth = async (response: any) => {
    const refreshToken = response.refresh_token;
    const expiry = response.access_token_expiry;
    const accessToken = response.access_token;

    if (refreshToken && expiry && accessToken) {
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("expiry", expiry);

      const userId = response.user.data.id;
      if (userId) {
        let users = await UserService.GetUsers({
          id: userId,
        });

        if (users.length) {
          this.SaveCurrentUser(users[0]);
        }
      }
    }
  };

  static SaveCurrentUser = (user: any) => {
    localStorage.setItem("current_user", user ? JSON.stringify(user) : "");
  };

  static GetCurrentUser = () => {
    let currentUserJsonText = localStorage.getItem("current_user");
    if (currentUserJsonText) {
      return JSON.parse(currentUserJsonText);
    }
  };

  static RefreshAuth = async () => {
    const currentTime = Date.now().toString().slice(0, 10);
    const expiryTime = localStorage.getItem("expiry")!.toString();
    const refreshToken = localStorage.getItem("refreshToken")!.toString();
    const apiUrl = ApiConfig.API_URL + ApiConfig.USER_OAUTH;

    if (!expiryTime) {
      return false;
    }

    if (expiryTime > currentTime) {
      return false;
    }

    try {
      const response = await ApiClient.sendPost(
        null,
        apiUrl,
        {
          action_task: "social_auth",
          refresh_token: refreshToken,
        },
        false
      );

      this.SaveAuth(response);
    } catch (error) {
      console.error(error);
    }
  };

  static Logout = () => {
    localStorage.setItem("refreshToken", "");
    localStorage.setItem("accessToken", "");
    localStorage.setItem("expiry", "");
    localStorage.setItem("current_user", "");
    const unregisterTopics = async () => {
      try {
        const data = await ChatRoomService.GetChatRoomList({
          user_id: AuthService.GetCurrentUser().id,
        });
        const chatRooms: Array<string> = data.map(
          (room: ContactProps) => `chat_room_${room.id}`
        );
        PushNotificationService.UnRegisterTopics({
          topics: JSON.stringify(chatRooms),
          client_token: localStorage.getItem("firebase_token"),
        });
      } catch (err) {
        console.log(err);
      }
    };
    unregisterTopics();
  };

  // TODO: need works
  static IsLoggedIn = async () => {
    const accessToken = localStorage.getItem("accessToken")!.toString();
    const refreshToken = localStorage.getItem("refreshToken")!.toString();
    const currentTime = Date.now().toString().slice(0, 10);
    const expiryTime = localStorage.getItem("expiry")!.toString();

    if (!accessToken || !refreshToken) {
      return false;
    }

    if (!expiryTime) {
      return false;
    }

    if (expiryTime > currentTime) {
      // TODO: need to complete this one
      let refreshAuthResult = await this.RefreshAuth();
    }

    return true;
  };
}

export default AuthService;
