import InviteService from "api/services/InviteService";
import UserService from "api/services/UserService";
import PostService from "api/services/PostService";
import MPService from "api/services/MixpanelService";
import { ChangeEvent, FormEvent, useState } from "react";
import Notify from "../Notify/Notify";
import "./Form.scss";

const mailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

function Form() {
  const [input, setInput] = useState<string>("");
  const [notify, setNotify] = useState({
    text: "",
    isOpen: false,
    status: false,
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    MPService.Track("User subscribes");
    e.preventDefault();
    if (input && input.match(mailRegex)) {
      InviteService.CreateInvite({ email: input });

      setNotify({
        text: "Thanks for subcribing, we will reach you in no time!",
        isOpen: true,
        status: true,
      });

      //Mixpanel track
      MPService.Identify(input);
      MPService.SetPeople({ $email: input });
      MPService.Track("User subscribes successfully");

      // closeNotify();
    } else {
      setNotify({
        text: "Please input your correct email address!",
        isOpen: true,
        status: false,
      });
      // closeNotify();
      MPService.Track("User subscribes unsuccessfully");
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
    setNotify({ text: "", isOpen: false, status: false });
    MPService.Track("User inputs email");
  };

  // const closeNotify = () => {
  //   setTimeout(() => {
  //     setNotify({ text: "", isOpen: false, status: false });
  //   }, 3000);
  // };

  return (
    <form
      role="form"
      className="get-started-form"
      id="new_castlebird_api_invite"
      action="/en/soon/new_invite?from=home"
      acceptCharset="UTF-8"
      data-remote="true"
      method="post"
      onSubmit={(e) => handleSubmit(e)}
    >
      <fieldset className="form-group form-group-lg get-started-email">
        <div className="form-group">
          <input
            placeholder="Email"
            required={true}
            className="form-control"
            type="email"
            name="castlebird_api_invite[email]"
            id="castlebird_api_invite_email"
            value={input}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </fieldset>
      <button
        name="button"
        type="submit"
        className="btn btn-lg btn-block btn-primary text-white coming_soon-btn"
      >
        Subcribe
      </button>
      <Notify
        text={notify.text}
        isOpen={notify.isOpen}
        status={notify.status}
      />
    </form>
  );
}

export default Form;
