import { ReactElement, useRef } from "react";
import useClickOutside from "hooks/useClickOutside";

interface DrawerProps {
  className: string;
  children: ReactElement;
  open: boolean;
  title?: string;
  onClick: (e: Event) => void;
}

function Drawer({ className, children, open, onClick, title }: DrawerProps) {
  const drawer = useRef<HTMLDivElement>(null);
  useClickOutside(drawer, onClick);
  if (open) {
    return (
      <div
        style={{ position: "absolute", borderRadius: "8px" }}
        className={className}
        ref={drawer}
      >
        {title && (
          <h3
            className="text-capitalize"
            style={{
              width: "100%",
              padding: "24px",
              borderBottom: "1px solid #E2E3E4",
            }}
          >
            {title}
          </h3>
        )}

        {children}
      </div>
    );
  }
  return null;
}

export default Drawer;
