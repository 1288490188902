import Mess_Empty from "assets/Chat/mess_empty.svg";

function EmptyMess() {
  return (
    <div
      className="flex-vertical"
      style={{
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        gap: "8px",
      }}
    >
      <img src={Mess_Empty} alt="Empty messagee image" />
      <h2 className="text-center">Nothing to See Here yet</h2>
      <span className="text-s text-center">
        Your conversations with professionals will appear here
      </span>
    </div>
  );
}

export default EmptyMess;
