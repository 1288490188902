import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import PostModal from "./SwiperModal";

interface PostProps {}

const Post: FunctionComponent<PostProps> = () => {
  const data = useOutletContext<Array<any>>();
  const navigate = useNavigate();
  const { profileId, postId } = useParams();

  console.log(data);

  return (
    <PostModal
      open={true}
      data={data}
      onClose={() => navigate(`/profile/${profileId}`)}
      index={postId}
    />
  );
};

export default Post;
