// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
  Messaging,
} from "firebase/messaging";
import "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyDjnTv3Xn0XqG30UvNZpw1TqzhKK8_iu6E",
  authDomain: "castlebird-prod-289110.firebaseapp.com",
  projectId: "castlebird-prod-289110",
  storageBucket: "castlebird-prod-289110.appspot.com",
  messagingSenderId: "821735858312",
  appId: "1:821735858312:web:92269b903bd41b4c217f3d",
  measurementId: "G-XMNYQN8H1G",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

let messaging: Messaging;

isSupported().then((data) => {
  if (data) {
    messaging = getMessaging(app);
  } else {
    console.log("Browser is not support firebase");
  }
});

const publicKey =
  "BMYJmaTeaU3uDLmQwO0AZgca_LAakU2d1CpKD9eiKbNfAw6d9flrLT2kcW5hPSPPXWI0L2o3bTEXUfYOcu7pLO4";
const serverKey =
  "AAAAv1NGIIg:APA91bEcdABoAUIqTvp-i7KMo42qMpeRVIYXwY3IbDF0t50cblVsHXU08BfkHSFFQY5fUOvGjTvvJojZi3A4qsLsi5lExrl6sw9qpSiin9aKjXvAwr2srzBtOd2760uECZYTAaIlP43w";
export const requestToken = async () => {
  let currentToken = "";
  if (!messaging) return;
  try {
    currentToken = await getToken(messaging, { vapidKey: publicKey });
    if (currentToken) {
      // console.log("current token for client: ", currentToken);
      localStorage.setItem("firebase_token", currentToken);
      return currentToken;
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
      Notification.requestPermission();
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

// export const subscribeToTopic = (
//   topicsName: Array<string>,
//   handler = (payload: any, res: any) => {}
// ) =>
//   getToken(messaging, { vapidKey: publicKey }).then((currentToken) => {
//     if (currentToken) {
//       // Subscribe to the topic
//       const topicURL = `https://iid.googleapis.com/iid/v1/${currentToken}/rel/topics/`;
//       const requestOptions = {
//         method: "POST",
//         headers: {
//           Authorization: `key=${serverKey}`,
//         },
//         body: JSON.stringify({ title: "Subscribe to Topics" }),
//       };
//       const promises = topicsName.map((topic: string) => {
//         return fetch(topicURL + topic, requestOptions);
//       });

//       // return promises.forEach((promise) => {
//       //   return promise
//       //     .then((response) => {
//       //       onMessage(messaging, (payload) => {
//       //         handler(payload, response);
//       //       });
//       //     })
//       //     .catch((err) => {
//       //       console.error(`Can't subscribe to ${promise}`, err);
//       //     });
//       // });
//       // return fetch(topicURL, requestOptions)
//       //   .then((response) => {
//       //     console.log(response);
//       //     onMessage(messaging, (payload) => {
//       //       handler(payload, response);
//       //     });
//       //   })
//       //   .catch(() => {
//       //     console.error(`Can't subscribe to ${topicsName} topic`);
//       //   });
//       return Promise.allSettled(promises).then((responses) =>
//         responses.forEach((res) => {
//           onMessage(messaging, (payload) => {
//             handler(payload, res);
//           });
//         })
//       );
//     }
//   });

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
