import ApiClient from "api/apiClient";
import ApiConfig from "api/apiConfig";

class InviteService {
  static type = "invites";

  static CreateInvite = async (bodyParams: any) => {
    // bodyParams: email, ip, user_agent, referer
    const url = ApiConfig.API_URL + ApiConfig.INVITE_CREATE;
    try {
      const response = await ApiClient.sendPost(
        this.type,
        url,
        bodyParams,
        false
      );
      console.log(response.data);
      return response;
    } catch (error) {
      console.error(error);
    }
  };
}

export default InviteService;
