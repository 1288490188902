export function getRandomDate() {
  const maxDate = Date.now();
  const timestamp = Math.floor(Math.random() * maxDate);
  return new Date(timestamp);
}

export enum ActionKind {
  SET_PRO = "SET_PRO",
  SEND_MESS = "SEND_MESS",
  SHOW_CONTENT = "SHOW_CONTENT",
  SELECT_CHAT = "SELECT_CHAT",
  SET_CURRENT_USER = "SET_CURRENT_USER",
}

export interface GlobalStateInterface {
  currentUser: any;
  proInfo: any;
  chat: Array<Partial<ChatHistoryProps>>;
  chatContent: boolean;
  contact: {
    id: number;
    imgUrl: string;
    name: string;
    mess: string;
    time: string;
    read: boolean;
  } | null;
}

export type ActionType = {
  type: ActionKind;
  payload?: any;
};

export interface IContextProps {
  state: GlobalStateInterface;
  dispatch: (action: ActionType) => void;
}
export interface ChatHistoryProps {
  type: string;
  message: string;
  text: string;
  incoming: boolean;
  outgoing: boolean;
  subtype: string;
  img: string;
  reply: string;
  preview: string;
  time: Date;
}

export const initialState: GlobalStateInterface = {
  currentUser: null,
  proInfo: null,
  chat: [
    {
      type: "session",
      img: "",
      text: "Video Consultation Session",
      incoming: false,
      outgoing: true,
      time: getRandomDate(),
    },
    {
      type: "msg",
      message:
        "Hi Daniel! Just saw your profile and your services. They are awesome but I want to ask you something first.",
      incoming: false,
      outgoing: true,
      time: getRandomDate(),
    },
    {
      type: "msg",
      message:
        "Hi Kylie, thanks for connecting with me as your Professional. What question do you have?",
      incoming: true,
      outgoing: false,
      time: getRandomDate(),
    },
    {
      type: "invite",
      message: "Can you send me an abstarct image?",
      text: "Daniel has invited you to a video session",
      incoming: true,
      outgoing: false,
      time: getRandomDate(),
    },
    {
      type: "session",
      img: "",
      text: "Video Consultation Session",
      incoming: false,
      outgoing: true,
      time: getRandomDate(),
    },
    {
      type: "msg",
      message:
        "Hi Daniel! Just saw your profile and your services. They are awesome but I want to ask you something first.",
      incoming: false,
      outgoing: true,
      time: getRandomDate(),
    },
    {
      type: "msg",
      message:
        "Hi Kylie, thanks for connecting with me as your Professional. What question do you have?",
      incoming: true,
      outgoing: false,
      time: getRandomDate(),
    },
    {
      type: "invite",
      message: "Can you send me an abstarct image?",
      text: "Daniel has invited you to a video session",
      incoming: true,
      outgoing: false,
      time: getRandomDate(),
    },
  ],
  chatContent: false,
  contact: null,
};

const reducer = (state: GlobalStateInterface, action: ActionType): any => {
  switch (action.type) {
    case ActionKind.SET_PRO:
      return {
        ...state,
        proInfo: action.payload,
      };
    case ActionKind.SEND_MESS:
      return {
        ...state,
        chat: [
          ...state.chat,
          {
            type: "msg",
            message: action.payload,
            incoming: false,
            outgoing: true,
            time: getRandomDate(),
          },
        ],
      };
    case ActionKind.SHOW_CONTENT:
      return {
        ...state,
        chatContent: action.payload,
      };
    case ActionKind.SELECT_CHAT:
      return {
        ...state,
        contact: action.payload,
      };
    case ActionKind.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
