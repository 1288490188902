import AuthService from "api/services/authService";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PATH_APP } from "routes/path";

const itemStyle = {
  display: "block",
  padding: "16px 24px",
  borderBottom: "1px solid #D0D0D0",
  fontWeight: 500,
  width: "100%",
};

interface AccountDrawerProps {
  logout: () => void;
  onClose: () => void;
  avatar: string;
  name: string;
  balance: string;
  permaid: string;
}

function AccountDrawer({
  logout,
  onClose,
  avatar,
  name,
  balance,
  permaid,
}: Partial<AccountDrawerProps>) {
  const [isUser, setIsUser] = useState(false);

  useEffect(() => {
    if (AuthService.GetCurrentUser().job_data) {
      setIsUser(false);
    } else {
      setIsUser(true);
    }
  }, []);

  return (
    <div className="account-container">
      <div
        className="account-user account-item flex-vertical"
        style={{ ...itemStyle, alignItems: "flex-start", gap: "16px" }}
      >
        <h3 style={{ marginBottom: "16px" }}>Account</h3>
        <div className="account-user flex-horizontal">
          <div
            className="user-avatar"
            style={{
              width: "48px",
              height: "48px",
              borderRadius: "100%",
              backgroundColor: "#333",
              marginRight: "12px",
              overflow: "hidden",
            }}
          >
            <img src={avatar} alt="user avatar image" />
          </div>
          <div
            className="user-info flex-vertical"
            style={{ alignItems: "flex-start" }}
          >
            <span className="user-name text-capitalize text-s-semibold">
              {name}
            </span>
            <span className="user-mail text-s">Your balance: {balance}</span>
          </div>
        </div>
      </div>
      <div className="account-item body-reg" aria-disabled>
        <Link
          to={`/profile/${permaid}`}
          // reloadDocument
          style={itemStyle}
          onClick={onClose}
          // className={isUser ? "disable-link" : ""}
        >
          Profile page
        </Link>
      </div>
      {/* {isUser ? (
        <div className="account-item body-reg" aria-disabled>
          <Link
            to={`/login/professional`}
            // reloadDocument
            style={itemStyle}
            onClick={onClose}
          >
            Become a professional
          </Link>
        </div>
      ) : null} */}
      {/* <div className="account-item body-reg">
        <Link
          to={PATH_APP.private.user_information}
          style={itemStyle}
          onClick={onClose}
        >
          Account Settings
        </Link>
      </div> */}
      {/* <div className="account-item body-reg">
        <Link to={"#"} style={itemStyle} onClick={onClose}>
          Contact Support
        </Link>
      </div> */}
      <div
        className="account-item body-reg"
        style={{ ...itemStyle, color: "red", border: "none" }}
        onClick={logout}
      >
        Logout
      </div>
    </div>
  );
}

export default AccountDrawer;
