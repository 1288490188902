import React, { ReactElement } from "react";

interface BadgeProps {
  icon: ReactElement;
  noti: boolean;
  onClick?: () => void;
}

function Badge({ icon, noti, onClick }: BadgeProps) {
  return (
    <div
      className="badge-icon"
      style={{ position: "relative" }}
      onClick={onClick}
    >
      {icon}
      {noti && (
        <div
          className="badge-noti"
          style={{
            position: "absolute",
            width: "7px",
            height: "7px",
            borderRadius: "100%",
            backgroundColor: "red",
            top: 2,
            right: 0,
          }}
        ></div>
      )}
    </div>
  );
}

export default Badge;
