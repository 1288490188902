const ApiConfig = {
  API_URL: "https://api.castlebird.com",
  // API_URL: "http://18.142.216.71:8081",

  USER_OAUTH: "/users/oauth",
  USER_INDEX: "/users/",
  USER_FOLLOW: "/user_follows",

  POST_INDEX: "/posts",
  SERVICE_INDEX: "/services",
  CHAT_ROOM_INDEX: "/chat_rooms",
  MESSAGE_INDEX: "/messages",
  SETTINGS_INDEX: "/settings",

  INVITE_CREATE: "/invites",
  PUSH_NOTI_REGISTER: "/pushnoti/register",
  PUSH_NOTI_UNREGISTER: "/pushnoti/unregister",
};

export default ApiConfig;
