import { useContext, useEffect, useRef, useState } from "react";
import { StateContext } from "store/Provider";
import Conversation, { MessProps } from "./Conversation";
import EmptyMess from "./EmptyMess";
import QR_code from "assets/Chat/QR.jpg";
import MessInput from "./MessInput";
import { useParams } from "react-router-dom";
import MessagesService from "api/services/MessageService";
import useClickOutside from "hooks/useClickOutside";
import LoadingSuspense from "components/Suspense";
import { onMessageListener } from "../../../Firebase";
import "broadcastchannel-polyfill";

function MessContent() {
  const { chat_room_id } = useParams();
  const modal = useRef<HTMLDivElement>(null);
  const { state } = useContext(StateContext);
  const { chatContent } = state;
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<MessProps[]>([]);
  const [messSent, setMessSent] = useState<MessProps>();

  const [openModal, setOpenModal] = useState(false);

  useClickOutside(modal, () => setOpenModal(false));

  useEffect(() => {
    //Listen to foreground message
    onMessageListener()
      .then((payload: any) => {
        console.log("Foreground message: ", payload);
        if (payload.data.chat_room_id === chat_room_id) {
          setMessages((prev) => [
            ...prev,
            {
              body: payload.data.body,
              call_log_data: "",
              call_name: "",
              chat_room_id: Number(payload.data.chat_room_id),
              created_at: payload.data.created_at,
              currency: "",
              id: payload.messageId,
              message_type: payload.data.type,
              picture_data: {
                id: 1,
                large: "",
                original: "",
                position: 1,
                snapshot: "",
              },
              premium_status: "free",
              price: "0",
              status: "sent",
              updated_at: new Date(Date.now()).toString(),
              user_avatar: payload.data.user_avatar,
              user_id: payload.data.user_id,
              user_name: payload.data.user_name,
              video_data: null,
            },
          ]);
        }
      })
      .catch((err) => console.log("failed: ", err));
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (messSent?.body.length) setMessages((prev) => [...prev, messSent]);
  }, [messSent]);

  useEffect(() => {
    const getMessages = async () => {
      setLoading(true);
      try {
        const data = await MessagesService.GetMessages({
          chat_room_id: chat_room_id,
        });
        setMessages(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    //Listen to the background message
    const fcmChannel = new BroadcastChannel("fcm-channel");
    fcmChannel.onmessage = (message) => {
      console.log("Broadcast channel", message);
      if (message.data.data.chat_room_id === chat_room_id) {
        setMessages((prev) => [
          ...prev,
          {
            body: message.data.data.body,
            call_log_data: "",
            call_name: "",
            chat_room_id: Number(message.data.data.chat_room_id),
            created_at: message.data.data.created_at,
            currency: "",
            id: message.data.messageId,
            message_type: message.data.data.type,
            picture_data: {
              id: 1,
              large: "",
              original: "",
              position: 1,
              snapshot: "",
            },
            premium_status: "free",
            price: "0",
            status: "sent",
            updated_at: new Date(Date.now()).toString(),
            user_avatar: message.data.data.user_avatar,
            user_id: message.data.data.user_id,
            user_name: message.data.data.user_name,
            video_data: null,
          },
        ]);
      } else {
        console.log("wrong chat room");
      }
    };
    getMessages();
  }, [chat_room_id]);

  return (
    <div
      className={`conversation flex-vertical ${
        chatContent && "chat-content__active"
      }`}
      style={{ height: "calc(100% - 90px)" }}
    >
      {loading ? (
        <LoadingSuspense
          style={{ width: "100%", backgroundColor: "rgb(250,250,250)" }}
        />
      ) : (
        <div className="chat-mess flex-vertical">
          {messages?.length ? (
            <Conversation
              loading={loading}
              messages={messages}
              messSent={messSent}
            />
          ) : (
            <EmptyMess />
          )}
        </div>
      )}

      <MessInput
        chat_room_id={chat_room_id}
        onSend={(mess) => setMessSent(mess)}
        onClick={() => setOpenModal(true)}
      />
      {openModal ? (
        <div
          ref={modal}
          className="chat-modal"
          style={{
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            width: "auto",
            maxWidth: "250px",
            height: "auto",
            zIndex: 40,
            backgroundColor: "#ebf3fa",
            borderRadius: "8px",
            overflow: "hidden",
            filter: "drop-shadow(0px 20px 50px rgba(0, 0, 0, 0.25))",
          }}
        >
          <div
            className="modal-content flex-vertical"
            style={{
              padding: "18px",
              justifyContent: "center",
              height: "100%",
              gap: 10,
            }}
          >
            <h4 className="lora-font text-center">
              Download our app to access full experiences
            </h4>
            <img src={QR_code} alt="QR code image" style={{ width: "100%" }} />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default MessContent;
