import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from "react-router-dom";
import RootLayout from "components/RootLayout";
import { PATH_APP, privateRoute, publicRoutes } from "./path";
import { ReactElement, Suspense } from "react";
import LoadingSuspense from "components/Suspense";
import SettingLayout from "components/SettingLayout/SettingLayout";
import AuthService from "api/services/authService";
import MessContent from "pages/Chat/components/MessContent";
import Post from "pages/PublicProfile/components/Work/components/Post";

export const PrivateRoute = ({ children }: { children: ReactElement }) => {
  return AuthService.GetCurrentUser() ? (
    <>{children}</>
  ) : (
    <Navigate replace={true} to={publicRoutes.pro_login.route} />
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={PATH_APP.root} element={<RootLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<LoadingSuspense />}>
              <publicRoutes.home.component />
            </Suspense>
          }
        />
        <Route
          path={`${publicRoutes.public_profile.route}:profileId`}
          element={
            <Suspense fallback={<LoadingSuspense />}>
              {<publicRoutes.public_profile.component />}
            </Suspense>
          }
        >
          <Route
            path={`${publicRoutes.public_profile.route}:profileId/post/:postId`}
            element={<Post />}
          ></Route>
        </Route>
        <Route
          path={publicRoutes.privacy_policy.route}
          element={
            <Suspense fallback={<LoadingSuspense />}>
              {<publicRoutes.privacy_policy.component />}
            </Suspense>
          }
        />
        <Route
          path={publicRoutes.terms_condition.route}
          element={
            <Suspense fallback={<LoadingSuspense />}>
              {<publicRoutes.terms_condition.component />}
            </Suspense>
          }
        />
        <Route
          path={publicRoutes.not_found.route}
          element={
            <Suspense fallback={<LoadingSuspense />}>
              {<publicRoutes.not_found.component />}
            </Suspense>
          }
        />
      </Route>
      <Route
        path={publicRoutes.pro_login.route}
        element={
          <Suspense fallback={<LoadingSuspense />}>
            {<publicRoutes.pro_login.component />}
          </Suspense>
        }
      />
      <Route
        path={publicRoutes.user_login.route}
        element={
          <Suspense fallback={<LoadingSuspense />}>
            {<publicRoutes.user_login.component />}
          </Suspense>
        }
      />

      <Route
        path={"*"}
        element={<Navigate to={publicRoutes.not_found.route} />}
      />
      <Route
        path={privateRoute.message.route}
        element={
          <Suspense fallback={<LoadingSuspense />}>
            <PrivateRoute>{<privateRoute.message.component />}</PrivateRoute>
          </Suspense>
        }
      >
        <Route
          path=":chat_room_id"
          element={
            <Suspense fallback={<LoadingSuspense />}>
              {<MessContent />}
            </Suspense>
          }
        ></Route>
      </Route>
      <Route path={PATH_APP.private.user} element={<SettingLayout />}>
        <Route
          path={privateRoute.user_information.route}
          element={
            <Suspense fallback={<LoadingSuspense />}>
              <PrivateRoute>
                {<privateRoute.user_information.component />}
              </PrivateRoute>
            </Suspense>
          }
        />
        <Route
          path={privateRoute.user_billing.route}
          element={
            <Suspense fallback={<LoadingSuspense />}>
              <PrivateRoute>
                {<privateRoute.user_billing.component />}
              </PrivateRoute>
            </Suspense>
          }
        />
      </Route>
      <Route
        path={`${privateRoute.info_filling.route}`}
        element={
          <Suspense fallback={<LoadingSuspense />}>
            {<privateRoute.info_filling.component />}
          </Suspense>
        }
      />
    </>
  )
);

export default router;
