import { Link, useLocation } from "react-router-dom";
import { PATH_APP } from "routes/path";

const itemStyle = {
  padding: "24px",
  width: "100%",
};

const itemActiveStyle = {
  ...itemStyle,
  backgroundColor: "#EBF3FA",
  fontWeight: "bold",
};

const itemClass = "sidebar-item text-capitalize body-reg";

function SettingSidebar() {
  const { pathname } = useLocation();

  const handleActiveClass = (location: string) => {
    if (pathname === location) return itemActiveStyle;
    return itemStyle;
  };

  return (
    <div
      className="setting-sidebar flex-vertical"
      style={{
        background: "#F7F7F7",
        border: "1px solid #E2E3E4",
        borderRadius: "8px",
        alignItems: "flex-start",
        overflow: "hidden",
      }}
    >
      <Link
        to={PATH_APP.private.user_information}
        className={itemClass}
        style={handleActiveClass(PATH_APP.private.user_information)}
      >
        General Information
      </Link>

      <Link
        to={"#"}
        className={itemClass}
        style={{
          ...handleActiveClass(PATH_APP.private.user_billing),
          cursor: "not-allowed",
        }}
      >
        Billing Method
      </Link>
    </div>
  );
}

export default SettingSidebar;
