import "components/Signup/style.scss";
// import Background1 from "assets/Home/bg/section-8-bg-1.png";
// import Background2 from "assets/Home/bg/section-8-bg-2.png";
import Facebook from "assets/Footer/facebook.png";
import Instagram from "assets/Footer/instagram.png";
import Twitter from "assets/Footer/twitter.png";
import Linkedin from "assets/Footer/linkedin.png";
import Form from "./components/Form/Form";

function Signup() {
  return (
    <>
      <section className="section-9 flex-vertical">
        <svg
          className="bg-1"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 800 400"
        >
          <path
            d="M131.83856201171875,124.6636734008789C145.44095357259116,112.55605061848958,183.70702107747397,64.1255594889323,213.45291137695312,52.01793670654297C243.19880167643228,39.91031392415365,285.50074768066406,27.354259490966797,310.31390380859375,52.01793670654297C335.12705993652344,76.68161392211914,340.2092793782552,152.76532363891602,362.33184814453125,200C384.4544169108073,247.23467636108398,409.86545817057294,312.257085164388,443.04931640625,335.4259948730469C476.23317464192706,358.59490458170575,531.6891072591146,355.9043375651042,561.4349975585938,339.0134582519531C591.1808878580729,322.12257893880206,611.5097147623698,251.56950887044272,621.524658203125,234.08071899414062"
            fill="none"
            strokeWidth="76"
            stroke="#fdcded"
            strokeLinecap="round"
          ></path>
          <defs>
            <linearGradient id="SvgjsLinearGradient1003">
              <stop stopColor="hsl(37, 99%, 67%)" offset="0"></stop>
              <stop stopColor="hsl(316, 73%, 52%)" offset="1"></stop>
            </linearGradient>
          </defs>
        </svg>
        <svg className="bg-2" viewBox="0 0 800 400">
          <path
            d="M761.4349975585938,-0.8968609571456909C759.7907511393229,15.24663696686427,773.8415730794271,69.20777465899785,751.5695190429688,95.96412658691406C729.2974650065104,122.72047851483028,678.4753214518229,148.13153584798178,627.8026733398438,159.64125061035156C577.1300252278646,171.15096537272134,492.5261535644531,156.20328013102213,447.53363037109375,165.0224151611328C402.5411071777344,173.8415501912435,373.5426025390625,192.0777359008789,357.8475341796875,212.55606079101562C342.1524658203125,233.03438568115234,350.9715932210286,257.1001383463542,353.36322021484375,287.8923645019531C355.7548472086589,318.68459065755206,369.05828348795575,379.0732421875,372.1972961425781,397.3094177246094"
            fill="none"
            strokeWidth="61"
            stroke="#7db1ed"
            strokeLinecap="round"
          ></path>
          <defs>
            <linearGradient id="SvgjsLinearGradient1004">
              <stop stopColor="hsl(37, 99%, 67%)" offset="0"></stop>
              <stop stopColor="hsl(316, 73%, 52%)" offset="1"></stop>
            </linearGradient>
          </defs>
        </svg>
        <h1 className="text-center">
          CastleBird is under Invite-Only <br /> Beta Testing!
        </h1>
        <h3 className="text-center">
          Put down your email to be the first one to try our mobile app
        </h3>
        {/* <Button
          text={"Became a professional"}
          color={"black"}
          height={"54px"}
        /> */}
        <Form />
        <div className="coming-soon-social flex-horizontal">
          <a
            className="coming-soon-social-circle"
            href="https://www.facebook.com/castlebirds"
            title="facebook-castlebird"
          >
            <img src={Facebook} alt="CastleBirdfacebook" />
          </a>
          <a
            className="coming-soon-social-circle"
            href="https://www.instagram.com/castlebirds/"
            title="instagram-castlebird"
          >
            <img src={Instagram} alt="CastleBirdinstagram" />
          </a>
          <a
            className="coming-soon-social-circle"
            href="https://twitter.com/castlebirds"
            title="twitter-castlebird"
          >
            <img src={Twitter} alt="CastleBirdtwitter" />
          </a>
          <a
            className="coming-soon-social-circle"
            href="https://www.linkedin.com/company/castlebird"
            title="instagram-castlebird"
          >
            <img src={Linkedin} alt="CastleBirdLinkedin" />
          </a>
        </div>

        <div className="coming-soon-copyright font-muslish">
          © Copyright CastleBird | All Rights Reserved
        </div>
      </section>
    </>
  );
}

export default Signup;
