import { useRef, useEffect } from "react";
import MessText from "./ConversationComponent/MessText";
import LoadingSuspense from "components/Suspense";
import AuthService from "api/services/authService";

export interface MessProps {
  body: string;
  call_log_data: any;
  call_name: string;
  chat_room_id: number;
  created_at: string;
  currency: string;
  id: string;
  message_type: "text" | "image" | "video";
  picture_data: {
    id: number;
    large: string;
    original: string;
    position: number;
    snapshot: string;
  };
  premium_status: string;
  price: string;
  status: "sent" | "seen";
  updated_at: string;
  user_avatar: string;
  user_id: number;
  user_name: string;
  video_data: any;
}

function Conversation({
  messages,
  messSent,
  loading,
}: {
  messages?: MessProps[];
  messSent?: MessProps;
  loading: boolean;
}) {
  const messContent = useRef<HTMLDivElement>(null);

  const userId: string = AuthService.GetCurrentUser().id;
  useEffect(() => {
    messContent.current?.scrollIntoView({ behavior: "smooth" });
  });

  return (
    <div className="conversation flex-vertical">
      {loading ? (
        <LoadingSuspense
          style={{
            width: "100%",
            backgroundColor: "transparent",
            height: "calc(100vh - 400px)",
          }}
        />
      ) : (
        messages?.map((mess) => {
          switch (mess.message_type) {
            case "text":
              return (
                <MessText
                  key={mess.id}
                  message={mess.body}
                  incoming={mess.user_id == Number(userId) ? false : true}
                  createdAt={mess.created_at}
                />
              );
            case "image":
              return (
                // <MessSession
                //   key={index}
                //   img={""}
                //   text={mess.text}
                //   incoming={mess.incoming}
                // />
                <MessText
                  key={mess.id}
                  message={mess.body}
                  incoming={mess.user_id == Number(userId) ? false : true}
                  imgUrl={mess.picture_data?.snapshot}
                  createdAt={mess.created_at}
                />
              );
            // case "invite":
            //   return <MessInvite key={index} time={mess.time} text={mess.text} />;
            default:
              return;
          }
        })
      )}
      <div style={{ float: "left", clear: "both" }} ref={messContent}></div>
    </div>
  );
}

export default Conversation;
