import ApiClient from "api/apiClient";
import ApiConfig from "api/apiConfig";

class PushNotificationService {
  static RegisterTopics = async (where: any) => {
    const url = ApiConfig.API_URL + ApiConfig.PUSH_NOTI_REGISTER;
    try {
      const response = await ApiClient.sendGet(url, where, true);
      console.log(response);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  static UnRegisterTopics = async (where: any) => {
    const url = ApiConfig.API_URL + ApiConfig.PUSH_NOTI_UNREGISTER;
    try {
      const response = await ApiClient.sendGet(url, where, true);
      console.log(response);
      return response;
    } catch (error) {
      console.log(error);
    }
  };
}

export default PushNotificationService;
