import ApiClient from "api/apiClient";
import ApiConfig from "api/apiConfig";
import { SendMessageInterface } from "types/ApiInterface";

class MessagesService {
  static type = "messages";

  static GetMessages = async (where: any, page?: any, order?: any) => {
    const url = ApiConfig.API_URL + ApiConfig.MESSAGE_INDEX;
    try {
      const response = await ApiClient.sendGet(url, where, true, page, order);
      console.log(response);
      return response;
    } catch (error) {
      console.log(error);
    }
  };
  static SendMessages = async (params: any) => {
    const url = ApiConfig.API_URL + ApiConfig.MESSAGE_INDEX;
    try {
      const response = await ApiClient.sendPost(this.type, url, params, true);
      console.log(response);
      return response;
    } catch (error) {
      console.log("Send message: ", error);
    }
  };
}

export default MessagesService;
