import "./App.scss";
import { RouterProvider } from "react-router-dom";
import { StateProvider } from "store/Provider";
import reducer, { initialState } from "store/Reducer";
import router from "routes/routes";
import MPService from "api/services/MixpanelService";
// import { requestToken } from "./Firebase";
import { useEffect } from "react";
import ChatRoomService from "api/services/ChatRoomService";
import AuthService from "api/services/authService";
import { ContactProps } from "pages/Chat/components/MessContact";
import PushNotificationService from "api/services/PushNotiService";
import "broadcastchannel-polyfill";

function App() {
  //Mixpanel initialize
  MPService.Init(`${process.env.REACT_APP_MIXPANEL_API_KEY}`, {
    debug: true,
    ignore_dnt: true,
    loaded: () => {
      MPService.Register({
        name: "visitor",
      });
    },
  });

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     if (Notification.permission === "denied") {
  //       console.log("Request Permission");
  //     }
  //   }, 3000);
  //   return () => clearInterval(timer);
  // }, []);

  // useEffect(() => {
  //   const fcmChannel = new BroadcastChannel("fcm-channel");
  //   fcmChannel.onmessage = (message) =>
  //     console.log("Background message", message);
  // }, []);

  const firebase_token = localStorage.getItem("firebase_token");

  useEffect(() => {
    // requestToken();
    const registerTopics = async () => {
      try {
        const data = await ChatRoomService.GetChatRoomList({
          user_id: AuthService.GetCurrentUser().id,
        });
        const chatRooms: Array<string> = data.map(
          (room: ContactProps) => `chat_room_${room.id}`
        );
        PushNotificationService.RegisterTopics({
          topics: JSON.stringify(chatRooms),
          client_token: firebase_token,
        });
      } catch (err) {
        console.log(err);
      }
    };
    registerTopics();
  }, []);

  const fcmChannel = new BroadcastChannel("fcm-channel");
  fcmChannel.onmessage = (message) => {
    console.log("Broadcast channel", message);
  };

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <RouterProvider router={router} />
    </StateProvider>
  );
}

export default App;
