import { Outlet } from "react-router-dom";
import NavBar from "components/Navbar";
import UserSidebar from "./UserSidebar";

import "./styles/styles.scss";

function SettingLayout() {
  return (
    <div className="setting-layout">
      <NavBar />
      <div className="setting-container flex-horizontal">
        <UserSidebar />
        <Outlet />
      </div>
    </div>
  );
}

export default SettingLayout;
