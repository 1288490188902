import NavBar from "components/Navbar";
import { Outlet, useMatch } from "react-router-dom";
import Signup from "./Signup";
import { IoIosArrowDropupCircle } from "react-icons/io";

function RootLayout() {
  const match = useMatch("/404");
  return (
    <>
      <NavBar />
      <Outlet />
      {!match && <Signup />}
      {/* <div
        style={{
          position: "fixed",
          zIndex: 99,
          bottom: 20,
          right: 20,
          cursor: "pointer",
        }}
        onClick={() => window.scrollTo(0, 0)}
      >
        <IoIosArrowDropupCircle size={36} color="" />
      </div> */}
    </>
  );
}

export default RootLayout;
