import { ChatHistoryProps } from "store/Reducer";
import { timeSince } from "../MessContact";

interface MessText {
  message: string;
  incoming: boolean;
  imgUrl?: string;
  createdAt: string;
}

function MessText({ message, incoming, imgUrl, createdAt }: MessText) {
  return (
    <div
      className={`mess mess-text text-s flex-vertical ${
        incoming ? "mess-text-in" : "mess-text-out"
      } ${imgUrl ? "img-mess" : ""}`}
    >
      <p style={{ fontSize: "16px" }}>{message}</p>
      {imgUrl && <img src={imgUrl} alt="" />}
      <p style={{ fontSize: "12px" }}>{timeSince(new Date(createdAt))}</p>
    </div>
  );
}

export default MessText;
