import AuthService from "api/services/authService";
import React, { useState } from "react";
import { BiCheckDouble } from "react-icons/bi";

interface ListContactProps {
  imgUrl: string;
  contactName: string;
  mess: string;
  time: string;
  select: boolean;
  read: boolean;
  status: number;
  onClick: () => void;
}
function ListContact({
  imgUrl,
  contactName,
  mess,
  time,
  select,
  status,
  read = true,
  onClick,
}: Partial<ListContactProps>) {
  const { avatar_snapshot, name } = AuthService.GetCurrentUser();
  return (
    <>
      {/* {mess ? ( */}
      <div
        className={`list-contact flex-horizontal ${select && "mess-select"}`}
        onClick={onClick}
      >
        <div className="contact-img">
          <div className="img-container">
            <img src={imgUrl ? imgUrl : avatar_snapshot} alt="" />
          </div>
        </div>
        <div className="contact-content flex-vertical">
          <div className="flex-horizontal">
            <h4 className="mess-name text-capitalize">
              {contactName ? contactName : name}
            </h4>
            {!read && mess && <div className="mess-noti"></div>}
          </div>
          <div className="mess-lastest text-s">
            {status?.toString() === AuthService.GetCurrentUser().id
              ? `You: ${mess}`
              : mess}
          </div>
          {mess ? (
            <div className="flex-horizontal">
              <div className="mess-time text-xs-semibold">{time}</div>
              {read && <BiCheckDouble size={20} color={"#2675EC"} />}
            </div>
          ) : null}
        </div>
      </div>
      {/* ) : null} */}
    </>
  );
}

export default ListContact;
