import axios from "axios";
import ApiConfig from "api/apiConfig";
import ApiClient from "api/apiClient";

class UserService {
  static type = "users";

  // static GetUsers = async () => {
  //     try {
  //         const response = await axios.get('https://random-data-api.com/api/v2/users')
  //         console.log(response.data)
  //         return response;
  //     } catch (error) {
  //         console.error(error);
  //     }
  // };

  static GetUsers = async (where: any) => {
    const url = ApiConfig.API_URL + ApiConfig.USER_INDEX;

    try {
      const response = await ApiClient.sendGet(url, where, false);
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  static UpdateUser = async (where: any, id: any) => {
    const url = ApiConfig.API_URL + ApiConfig.USER_INDEX + id;
    try {
      const response = await ApiClient.sendPatch(
        this.type,
        id,
        url,
        where,
        true
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  static GetFollowUser = async (where: any) => {
    const url = ApiConfig.API_URL + ApiConfig.USER_FOLLOW;
    try {
      const response = await ApiClient.sendGet(url, where, true);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  static FollowUser = async (where: any) => {
    const url = ApiConfig.API_URL + ApiConfig.USER_FOLLOW;
    const type = "user_follows";
    try {
      const response = await ApiClient.sendPost(type, url, where, true);
      console.log(response);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  static UnfollowUser = async (where: any, id: any) => {
    const url = ApiConfig.API_URL + ApiConfig.USER_FOLLOW + `/${id}`;
    try {
      const response = await ApiClient.sendDel(url, where, true);
      console.log(response);
      return response;
    } catch (error) {
      console.log(error);
    }
  };
}

export default UserService;
