import { lazy } from "react";

function path(root: string, sublink: string) {
  return `${root}${sublink}/`;
}

const ROOT_PATH_APP = "/";

export const PATH_APP = {
  root: ROOT_PATH_APP,
  generals: {
    home: path(ROOT_PATH_APP, ""),
    public_profile: path(ROOT_PATH_APP, "profile"),
    pro_login: path(ROOT_PATH_APP, "login/professional"),
    user_login: path(ROOT_PATH_APP, "login/user"),
    not_found: path(ROOT_PATH_APP, "404"),
    privacy_policy: path(ROOT_PATH_APP, "privacy-policy"),
    terms_condition: path(ROOT_PATH_APP, "terms-of-use"),
  },
  private: {
    message: path(ROOT_PATH_APP, "message"),
    message_room: path(ROOT_PATH_APP, "message/chatroom"),
    user: path(ROOT_PATH_APP, "user"),
    user_information: path(ROOT_PATH_APP, "user/information"),
    user_billing: path(ROOT_PATH_APP, "user/billing"),
    info_filling: path(ROOT_PATH_APP, "profile/info-filling"),
  },
};

export const publicRoutes = {
  home: {
    route: PATH_APP.generals.home,
    component: lazy(() => import("pages/Home")),
  },
  public_profile: {
    route: PATH_APP.generals.public_profile,
    component: lazy(() => import("pages/PublicProfile")),
  },
  not_found: {
    route: PATH_APP.generals.not_found,
    component: lazy(() => import("pages/404")),
  },
  pro_login: {
    route: PATH_APP.generals.pro_login,
    component: lazy(() => import("pages/ProLogin")),
  },
  user_login: {
    route: PATH_APP.generals.user_login,
    component: lazy(() => import("pages/UserLogin")),
  },
  privacy_policy: {
    route: PATH_APP.generals.privacy_policy,
    component: lazy(() => import("pages/Policy")),
  },
  terms_condition: {
    route: PATH_APP.generals.terms_condition,
    component: lazy(() => import("pages/Terms")),
  },
};

export const privateRoute = {
  message: {
    route: PATH_APP.private.message,
    component: lazy(() => import("pages/Chat")),
  },
  user_information: {
    route: PATH_APP.private.user_information,
    component: lazy(() => import("pages/UserInfo")),
  },
  user_billing: {
    route: PATH_APP.private.user_billing,
    component: lazy(() => import("pages/UserBilling")),
  },
  info_filling: {
    route: PATH_APP.private.info_filling,
    component: lazy(() => import("pages/Info")),
  },
};
