import "./Notify.scss";

function Notify({
  text,
  isOpen,
  status,
}: {
  text: string;
  isOpen: boolean;
  status: boolean;
}) {
  return isOpen ? (
    <div
      style={{ color: status ? "#21a9e1" : "black" }}
      className="mail-notify text-s-semibold"
    >
      {text}
    </div>
  ) : null;
}

export default Notify;
