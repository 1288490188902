import Close from "assets/Navbar/close.png";
import useClickOutside from "hooks/useClickOutside";
import { useRef } from "react";
import "./style.scss";
import { Link, useMatch } from "react-router-dom";
import { UserInterface } from "types/ApiInterface";

interface NavbarMobileProps {
  isOpen: boolean;
  isLogin: boolean;
  user?: UserInterface;
  onClick: () => void;
  logOut: () => void;
}

function NavbarMobile({
  isOpen,
  onClick,
  user,
  isLogin,
  logOut,
}: NavbarMobileProps) {
  const navbar = useRef<HTMLDivElement>(null);
  useClickOutside(navbar, onClick);

  const match = useMatch("/");

  const onFeatures = () => {
    const feature = document.querySelector(".section-2");
    feature?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const onAbout = () => {
    const about = document.querySelector(".section-5");
    about?.scrollIntoView({
      behavior: "smooth",
    });
  };
  const onContact = () => {
    const contact = document.querySelector(".section-9");
    contact?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return isOpen ? (
    <div className="nav-mobile-container" ref={navbar}>
      <img
        className="nav-mobile-menu"
        id="menu-mobile"
        src={Close}
        alt=""
        onClick={onClick}
      />
      <ul>
        {match && (
          <>
            <li>
              <span className="text-s text-uppercase" onClick={onFeatures}>
                Features
              </span>
            </li>
            <li>
              <span className="text-s text-uppercase" onClick={onAbout}>
                About us
              </span>
            </li>
            <li>
              <span className="text-s text-uppercase" onClick={onContact}>
                Contact us
              </span>
            </li>
          </>
        )}
        {isLogin ? (
          <>
            <li>
              <Link to={"/message"} className="text-s text-uppercase">
                Chat
              </Link>
            </li>
            <li>
              <Link
                to={`/profile/${user?.permaid}`}
                className="text-s-semibold text-uppercase"
              >
                {user?.name}
              </Link>
            </li>

            <li className="logout-btn">
              <button className="button" onClick={logOut}>
                <div className="text text-capitalize">Logout</div>
              </button>
            </li>
          </>
        ) : (
          <>
            <li>
              <Link to={"/login/professional"}>
                <button className="button signin-btn flex-horizontal">
                  <div className="text text-capitalize">Login</div>
                </button>
              </Link>
            </li>
          </>
        )}
      </ul>
    </div>
  ) : null;
}

export default NavbarMobile;
