import useClickOutside from "hooks/useClickOutside";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { BsEmojiSmile, BsPlusSquare } from "react-icons/bs";
import { IoSend } from "react-icons/io5";
import { StateContext } from "store/Provider";
import { ActionKind } from "store/Reducer";
import EmojiPicker, { EmojiClickData, EmojiStyle } from "emoji-picker-react";
import MessagesService from "api/services/MessageService";
import { MessProps } from "./Conversation";
import AuthService from "api/services/authService";

function MessInput({
  chat_room_id,
  onSend,
  onClick,
}: {
  chat_room_id?: string;
  onSend: (message: MessProps) => void;
  onClick: () => void;
}) {
  const { dispatch } = useContext(StateContext);
  const [mess, setMess] = useState("");
  const [emoji, setEmoji] = useState<string>("");
  const [fileList, setFileList] = useState<FileList | null>(null);
  const [emojiOpen, setEmojiOpen] = useState(false);
  const textInput = useRef<HTMLTextAreaElement>(null);
  const fileInput = useRef<HTMLInputElement>(null);
  const emojiInput = useRef<HTMLDivElement>(null);

  const user = AuthService.GetCurrentUser();

  const handleChangeInput = (input: string) => {
    setMess(input);
  };
  const handleSelectEmoji = (emoji: EmojiClickData) => {
    setEmoji(emoji.emoji);
    textInput.current?.focus();
  };

  useEffect(() => {
    setMess((prev) => prev + emoji);
  }, [emoji]);

  const handleSendMess = () => {
    if (chat_room_id && mess.length) {
      MessagesService.SendMessages({
        chat_room_id: Number(chat_room_id),
        premium_status: "free",
        message_type: "text",
        body: mess,
      });
      onSend({
        body: mess,
        call_log_data: "",
        call_name: "",
        chat_room_id: Number(chat_room_id),
        created_at: new Date(Date.now()).toString(),
        currency: "",
        id: (Math.random() * 1000).toString(),
        message_type: "text",
        picture_data: {
          id: 1,
          large: "",
          original: "",
          position: 1,
          snapshot: "",
        },
        premium_status: "free",
        price: "0",
        status: "sent",
        updated_at: new Date(Date.now()).toString(),
        user_avatar: user.avatar_snapshot,
        user_id: user.id,
        user_name: user.name,
        video_data: null,
      });
      setMess("");
    }
    textInput.current?.focus();
  };
  const handleEnter = (key: string) => {
    key === "Enter" && handleSendMess();
  };
  const handleUploadFile = () => {
    alert("Please download our app to use this function");
  };
  const handleOpenEmoji = () => {
    setEmojiOpen(!emojiOpen);
  };
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFileList(e.target.files);
  };

  useClickOutside(emojiInput, () => {
    setEmojiOpen(false);
  });

  return (
    <div className="chat-input flex-horizontal">
      <button style={{ position: "relative" }} onClick={handleOpenEmoji}>
        <BsEmojiSmile size={24} />
        {emojiOpen && (
          <div
            style={{ position: "absolute", bottom: "160%" }}
            ref={emojiInput}
          >
            <EmojiPicker
              onEmojiClick={(emoji) => handleSelectEmoji(emoji)}
              skinTonesDisabled
            />
          </div>
        )}
      </button>
      {/* <button onClick={onClick}>
        <BsPlusSquare size={24} />
        <input
          disabled
          title="Download our app to use this function"
          type="file"
          multiple
          style={{ display: "none" }}
          ref={fileInput}
          onChange={handleFileChange}
        />
      </button> */}
      <textarea
        ref={textInput}
        value={mess}
        onChange={(e) => handleChangeInput(e.target.value)}
        rows={1}
        placeholder={"Type something"}
      ></textarea>
      <button onClick={handleSendMess} onKeyDown={(e) => handleEnter(e.key)}>
        <IoSend size={24} color={"#21A9E1"} />
      </button>
    </div>
  );
}

export default MessInput;
