import mixpanel from "mixpanel-browser";

export default class MPService {
  static Init = (token: string, props: Object) => {
    mixpanel.init(token, props);
  };
  static Register = (props: Object) => {
    mixpanel.register(props);
  };
  static Track = (event: string, props?: Object) => {
    mixpanel.track(event, props);
  };
  static Identify = (props: string) => {
    mixpanel.identify(props);
  };
  static SetPeople = (props: Object) => {
    mixpanel.people.set(props);
  };
}
