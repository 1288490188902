import { Navigation } from "swiper";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import "./styles.scss";
import { useRef, useState } from "react";
import useClickOutside from "hooks/useClickOutside";

interface PostModalProps {
  open: boolean;
  data: any;
  onClose: (event: Event) => void;
  index: string;
}

function PostModal({
  open,
  data,
  index,
  onClose = () => {},
}: Partial<PostModalProps>) {
  const modal = useRef<HTMLDivElement>(null);

  useClickOutside(modal, onClose);

  return (
    <>
      {open && (
        <div className="swiper-modal-container flex-vertical">
          <div className="modal-content" ref={modal}>
            {data.picture_data ? (
              <img
                src={data.picture_data[0].snapshot}
                alt=""
                style={{ maxWidth: "100%", height: "auto" }}
              />
            ) : (
              <video
                controls
                autoPlay
                src={data.video_data[0].type_360}
                style={{ width: "100%" }}
              ></video>
            )}
            <div
              className="moda-text"
              style={{ position: "absolute", bottom: 12, left: 12 }}
            >
              <h5
                style={{
                  color: "white",
                  textShadow: "2px 3px 4px #000",
                  fontWeight: "600",
                }}
              >
                {data.title}
              </h5>
              <p>{data.content}</p>
            </div>

            {/* <Swiper
              className="swiper-modal"
              initialSlide={data?.map((item) => item.id).indexOf(index)}
              loop
              allowSlideNext={false}
              allowSlidePrev={false}
            >
              {data?.map((item, index) => (
                <SwiperSlide
                  className={`modal-slide id-${item.id} flex-vertical`}
                  key={index}
                >
                  {item.picture_data ? (
                    <img src={item.picture_data[0].large} alt={item.name} />
                  ) : (
                    <video src={item.item.video_data[0].type_360}></video>
                  )}
                  <div className="slide-overlay"></div>
                  <div
                    className="text-s-semibold"
                    style={{
                      position: "absolute",
                      left: "12px",
                      bottom: "12px",
                      color: "white",
                    }}
                  >
                    {item.title}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper> */}
          </div>
        </div>
      )}
    </>
  );
}

export default PostModal;
